<template>
  <div>
    <div class="container-fluid mt-5">
      <div class="card-group">
        <div class="card" v-for="post in visiblePosts" :key="post.artid">
          <img :src="`${post.artpic}`" class="card-img-top" alt="">
          <div class="card-body">
            <h5 class="card-title">{{post.artdesc}}</h5>
       
          </div>
        </div>
      </div>
      
      <div class="pagingationdiv">
        <nav arial-laeble="Page navigation">
          <ul style="float: left; margin-right: 100px;">
            <li>
              <div>Results: <b>{{mytotalitems}} </b> items. 
                <span style=" margin-left: 20px;">
                  <select @change="getSelectedOption($event)">
                    <option value="" selected disabled>Page</option>
                    <option value="6">6</option>
                    <option value="9">9</option>
                    <option value="12">12</option>
                    <option value="27">27</option>
                  </select>
                </span>
              </div>

            </li>
          </ul>
          <ul>
            <li class="page-item">
                <a class="page-link innerpagelink" href="#" @click.prevent="changePage(currentPage -1)" :disabled="currentPage === 1">
                  <font-awesome-icon :icon="['fas', 'angles-left']" />
                  </a>
            </li>
          
            <!-- -pagination ----->
            <li class="page-item"
              v-for="pageNumber in visiblePageNumbers"
              :key:="pageNumber"
              :class="{active : currentPage == pageNumber || pageNumber === '...'}"
              >
              <div id="myDIV">
                <a class="page-link innerpagelink" 
                    href="#" @click.prevent="changePage(pageNumber)"  :id="`${pageNumber}`"
                    >
                  {{pageNumber}}
                </a>
              </div>
            </li>
          
            <!--  Next Button-->
            <li class="page-item">
              <a class="page-link innerpagelink"
                href="#"
                @click.prevent="changePage(currentPage + 1)"
                :disabled="currentPage === 1">
                <font-awesome-icon :icon="['fas', 'angles-right']" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
          <!--now create the button pagination -->  
    
    
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
 return {
  posts: [],
// set your item per page to show
  itemPerpage: 3,
// set teh current page
  currentPage: 1,
  mytotalpages: 0,
  mytotalitems: 0,
  selval: 0,
  pginatecss: parseInt(localStorage.getItem('elid'))
 }
},
computed: {
   visiblePosts() {
  //create the star page and endpage
    const startPage = (this.currentPage -1 ) * this.itemPerpage;
  //and end page
    const endPage = startPage + this.itemPerpage
    return this.posts.slice(startPage, endPage)
  },
  //calculate the total pages
  totalPages() {
     this.mytotalitems = parseInt(Math.ceil(this.posts.length))
  return Math.ceil(this.posts.length / this.itemPerpage)
  },

  // and create the button number
  // set 3page s in start and e in end.
  visiblePageNumbers() {
  let pageNumbers = []


  //  if ( this.totalPages <=7) {

  if ( this.totalPages <=7) {
    for (let i = 1; i <= this.totalPages; i++) {
      pageNumbers.push(i)
    }
  }else {
    //if(this.page <= 4) {
    if(this.currentPage <= 4) {
      pageNumbers = [1 , 2, 3, 4, 5,  "...", this.totalPages];
      // }else if(this.page >= this.totalPages - 3) {
    }else if(this.currentPage >= this.totalPages - 3) {
    // and if you click ----
        // then show number bew 3 buttons
        //pageNumbers = [1, "....", this.totalPages -4, totalPages -2, this.totalPages- 1, this.totalPages]
        pageNumbers = [1, "....", this.totalPages -4, totalPages - 3, this.totalPages - 2, this.totalPages - 1, this.totalPages]
        }else {
      pageNumbers = [1, "...", this.page - 1, "...", this.totalPages]
        }
  }
    return pageNumbers  
  }
},

methods: {
async fetchposts() {
await axios.get(`getarticlepaginated/`).then(
      response => {
        this.posts = response.data.results
        console.log(this.posts)
      })
      .catch((error) => {
          console.log(error)
        })
        
},
 //change page 
 changePage(currentPage) {
if(currentPage >= 1 &&  currentPage <= this.totalPages) {
   this.currentPage =  currentPage
   console.log(this.currentPage)
}

},



getSelectedOption($event){
  this.selval = $event.target.value
  this.itemPerpage = this.selval
},


},


mounted() {
this.fetchposts()
},
}


</script>


<!------ -----------------------------------------------------------------------------            -->



<!--script->
// import moment from 'moment';
// export default {
//     props: ['allnewsdata', 'showNextButton', 'showPreviousButton',],
//  data() {
//     return {
//         showNextButton: true,
//         showPreviousButton: true
//     }
//  },
// methods: {
//   dateTime(value) {
//       return moment(value).format('YYYY-MMMM-DD');
//     },
//   goToNextPage($event) {
//     // id: $event.target.value
//     this.$emit('goToNextPage', $event.target.value)
 
//     },
//   goToPreviousPage($event) {
//     // id: $event.target.value
//     this.$emit('goToPreviousPage', $event.target.value)
//     },
// },
// }
//</script-->

<style scoped>
.container-fluid {
background-color: black;
padding-top: 100px;
padding-bottom: 100px;
padding-left: 50px;
padding-right: 50px;
}
ul li {
display: inline-block;
}
.pagingationdiv {

  float: right;

}
nav {
  background-color: black;
  width: 100%;
  color: white;
}

.innerpagelink {
color: #003;
background-color: white;
width: 50px;
height: 32px;
text-align: center;
border: thin solid #003;
border-radius: 2px;
font-weight: bold;
font-size: 16px;
}
.innerpagelink:hover {
  background-color: orange;
  width: 60px;
  height: 35;
  font-weight: bold;
  font-size: 16px;
}

.innerpagelink:active {
  background-color: orangered;
  width: 60px;
  height: 35;
  font-weight: bold;
  font-size: 16px;
}

.previousbtn, .btnext {
  background-color: #003;
  color: white;
  width: 80px;
  text-align: center;
}
.previousbtn:hover, .btnext:hover {
 background-color: rgb(119, 108, 104);
 border-color:  rgb(119, 108, 104);
}


.btn {
  border: none;
  outline: none;
  padding: 10px 16px;
  background-color: #f1f1f1;
  cursor: pointer;
  font-size: 18px;
}

/* Style the active class, and buttons on mouse-over */
.active, .btn:hover {
  background-color: #666;
  color: white;
}
.pagingationdiv {
  background-color: black;
}
.card {
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 5px;
}
.card-group, .card {
  background-color: black;
}
.card-body {
  background-color: white;
}
.card {
  border: solid thin white
}
/* 
.newscardheader {
  background-image: url("../assets/slide-5.jpg");
  color: white;
  font-size: 24px;
}

.card-header {
  background-color: white;
  color: #003;
  font-weight: bold;
  text-align: center;
}
.servicescards {
  background-image: url("../assets/slide-5.jpg");
  color: white;
}

#oddnewsbg {
  color: grey;
  font-weight: normal;
  padding: 30px;
  background-color: rgb(244, 245, 243);
  border-top: thin solid #003;
  border-bottom: thin solid #003;
}


#evennewsbg {
  color: grey;
  font-weight: normal;
  padding: 30px;
  margin-top: 40px;
  border-top: thin solid #003;
  border-bottom: thin solid #003;
}


.max-lines {
  overflow: hidden;
  display: -webkit-box; 
          line-clamp: 2; 
  -webkit-box-orient: vertical;
  font-weight: normal;

}
.buttons {
  padding-top: 80px;
  text-align: center;
}
.card-title {
  font-size: 13;
  color: rgb(90, 65, 1);
  font-weight: bold;
}
.hptitle {
  font-weight: bold;
  color: rgb(20, 75, 126);
  cursor: pointer;
  width: 90%;
}
.downloadformat {
  float: right;
  font-size: 40px;
  margin-top: -80px;
}
.btnnformat {
  float: right;
}
.btnprevformat{
  float: left;
}
.newscardheader {
  color: whitesmoke;
}
.is-light  {
  float: right;
} */
@media (min-width: 320px) and (max-width: 575.99px) {
/*.col {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  background-image: none;
}*/
 }
 /*===================MEDIA SCREENS TAMPLATES===============*/
 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
  /*.newsphotoimage{
    height: 280px;
    margin-bottom: 0px;
   }
   .newscard {
    height: auto;
   }
   .col {
    width: 100%;
   } 
   .card-text{
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    width: 100%;
   }
   .hptitle {
      font-weight: bold;
      color: rgb(20, 75, 126);
      cursor: pointer;
      width: 80%;
    }
  .container {
     width: 100%;
  }*/
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {

 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {

 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
  .services {
    padding-bottom: 100px;
  }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    
 } 
</style>