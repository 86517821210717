<template>
  <div style="padding-top: 5px; margin-top:-10px; top: 0; background-color: black;">
  <nav style="background-color: black; padding-top: 20px;">
    <div class="wrapper">
      <router-link to="/" class="navbar-brand"><img class="imglogo" src="@/assets/logo1.png" /></router-link>
 
      <ul class="nav-links formatultopmenu">
        <li v-for="(menu, index) in mainmenu" :key="menu.mid">
          <a href="#" class="desktop-item" :id="`${menu.mid}`" @mouseover="getId($event, $event.target.value=menu.mid)"  :class="[menu.mid===gml1 ? 'menulevel1' : '']">{{ menu.mdesc }}</a>
          <input type="checkbox" >
          <label  class="mobile-item">{{ menu.mdesc }}</label>
          <div class="mega-box">
            <div class="content contentformat">
              <div class="row formatrow1">
                <ul class="mega-links">
                  <li v-for="(md, index2) in menualldesc" :key="index2.mfid_id">
                    <div  v-if="index2 === index">
                      <div v-html="md.fscaption" ></div>
                      <!-- <div><img :src="`${md.lsimage}`" ></div> --> 
                      <div v-html="md.fsecdesc" style="font-size: 14px; line-height: 1.5rem;"></div>
                    </div>
                  </li>
                </ul>

              </div>
              <div class="row formatrow4">
                <!-- <header>Design Services</header> -->
                <ul class="mega-links">
                  <li  v-for="secone in divsecone" :key="secone.did">
                    <router-link :to="{path: ''}" :id="`${secone.pk}`" :class="[secone.pk===gml2 ? 'menulevel2' : '']" 
                      @mouseover="getsectwoId($event, $event.target.value=secone.pk)" @click="goToOpenMenu($event, 
                      $event.target.value=secone.pk)">
                      {{ secone.fields['ds1desc']}}
                    </router-link>
                  </li>
                </ul>
              </div>
              <div class="row formatrow3">
                <!-- <header>Email Services</header> -->
                <ul class="mega-links">
                  <li v-for="sectwo in divsectwo" :key="sectwo.did2">
                    <span v-if="sectwo.fields['dsshowdesc']">
                      <div v-html="sectwo.fields['ds2desc']"></div>
                    </span>
                    <router-link 
                      :to="{path: sectwo.fields['ds2link']}" 
                      @mouseover="getsecthreeId($event, $event.target.value=sectwo.did2)"
                      :id="`${sectwo.pk}`" :class="[sectwo.did2===gml3 ? 'menulevel3' : '']"
                      >
                        {{ sectwo.fields['dsshortdesc']}}
                      </router-link>
                  </li>
                </ul>
              </div>
              <div class="row formatrow4">
                <ul class="mega-links">
                  <li v-for="(md, index2) in menualldesc" :key="index2.mfid_id">
                    <div  v-if="index2 === index">
                      <div v-html="md.lscaption" ></div>
                      <div><img :src="`${md.lsimage}`" ></div>
                      <div v-html="md.lsecdesc" style="font-size: 14px; line-height: 1.5rem; padding: 10px; padding-top: 20px;"></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
      
      <div class="d-flex ms-auto order-2">
        <router-link to="/"> 
          <a class="langen formatlang" @click.prevent="setLangEn()" id="langen" :class="[getlang==='en' ? 'setEnLang' : '']">EN</a> | 
          <span class="langfr formatlang" @click.prevent="setLangFr()" id="langfr" :class="[getlang==='fr' ? 'setFrLang' : '']">FR</span>
        </router-link>
        <span><font-awesome-icon icon="magnifying-glass" class="fonticonformat formatfa" data-bs-toggle="modal" data-bs-target="#staticBackdrop1" /></span>
        <span><font-awesome-icon icon="bars" class="formatfa offcanvasvisibility" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions"/></span>
        
      </div>
      
    </div>
  </nav>

</div>

<!-- Offcavas starts here-->
<div class="offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
  <div class="offcanvas-header">
   <router-link :to="{path: '/'}" class="navbar-brand"><img class="imglogo" src="@/assets/logo1.png" /></router-link>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <!--  Accordion top menu--> 
    <div class="accordion accordion-flush outeraccordion" id="accordionOffCanvas">
       <!-- Top Leve menu-->
      <div class="accordion-item" v-for="(menu, index) in mainmenu" :key="menu.mid">
        <h2 class="accordion-header" :id="`flush-heading${menu.mid}`">
          <button class="accordion-button collapsed forminnerbody" type="button" data-bs-toggle="collapse" :data-bs-target="`#flush-collapse${menu.mid}`" aria-expanded="false" :aria-controls="`flush-collapse${menu.mid}`">
            {{ menu.mdesc }}
          </button> 
        </h2>
        <div :id="`flush-collapse${menu.mid}`" class="accordion-collapse collapse" :aria-labelledby="`flush-heading${menu.mid}`" data-bs-parent="#accordionOffCanvas">
          <div class="accordion-body forminnerbody">
            <!-- top body text and inner head text-->
            <div v-for="(md, index2) in menualldesc" :key="index2.mfid_id">
              <div v-if="index2 === index">
                <!-- <div  class="forminnerbody topcaption forminnerbody">{{ md.msdesc }}</div> -->
                <div v-html="md.fsecdesc" class="forminnerbody extendedformatting"></div>
                 <!-- second level menu -->
                <div v-for="sec1 in divseconeinfo">
                  <div v-if="sec1.mfid === md.mids">
                    <button class="accordioninner" 
                    @click.prevent="openAccordion()"  
                    @mousedown="getAccordion()"
                    >{{sec1.ds1desc}}</button>
                      <div class="panel" >
                         <!-- Final menu links-->
                        <div v-for="dsv2 in divsectwoinfo">
                          <div v-if="dsv2.ds1fid === sec1.did">
                            <span v-if="dsv2.dsshowdesc">
                              <div v-html="dsv2.ds2desc" class="forminnerbody"></div>
                            </span>
                            <div v-else class="formatlinks">
                              <router-link :to="{path: dsv2.ds2link}" class="linksfmt">{{ dsv2.dsshortdesc}}</router-link>
                            </div>

                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
                      
              <!-- bottom body text-->
              <div  v-if="index2 === index" class="forminnerbody">
                <div v-html="md.lscaption" class="forminnerbody"></div>
                <div><img :src="`${md.lsimage}`" style="width: 100%;"></div>
                <div v-html="md.lsecdesc" class="forminnerbody extendedformatting"></div>
              </div>
            </div>

          </div>
          </div>
        </div>
      </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Search Dialog</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <SearchAutocomplete
        @input="onChange($event)"
        :items="items"
        @goToLink="closeModalNow($event)"
      />
      </div>
   
    </div>
  </div>
</div>
</template>


<script>
// import { mapGetters } from 'vuex';

import axios from 'axios';
import SearchAutocomplete from '@/components/SearchAutocomplete.vue'
import {  useRouter } from "vue-router";
import { faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import { useToast } from "vue-toastification";
import '../globalDeclare';


export default {

  props: ['breadcrumbdata', 'breadcrumbtheme'],
  components: {
    SearchAutocomplete
  },
  // props: ['user'],
  data () {
    const lang = localStorage.getItem('lang')  || 'en';
    return {
      lang: lang,
      mainmenu: [],
      menualldesc: [],
      divsecone: [],
      divsectwo: [],
      getmenu: '',
      getmenutwo: '',
      id: 0,
      items: [],
      getlang: localStorage.getItem("lang"),
      gml1: parseInt(localStorage.getItem("al1active")),
      gml2: parseInt(localStorage.getItem("al2active")),
      gml3: parseInt(localStorage.getItem("al3active")),
      myImgUrl: myUrlPath,
      divseconeinfo: [],
      divsectwoinfo: [],
    }
  },
  beforeCreate() {

},
  created() {
      window.addEventListener('popstate', this.popstateEventAction );
    },

  methods: {
    openAccordion(){
        //---------Accordion-----------------------------
        let acc = document.getElementsByClassName('accordioninner');
        let i;

      for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function() {
          this.classList.toggle("active");
          let panel = this.nextElementSibling;
          if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
          } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
          } 
        });
      }
    },

   getAccordion(){
    this.openAccordion()
   },

    popstateEventAction() {
        // ... some action triggered when the back button is clicked
        this.removePopstateEventAction();
      },
      removePopstateEventAction() {
        window.removeEventListener('popstate', this.popstateEventAction);
      },

    selectItem($event){
    let id = parseInt($event.target.value)
      try {
        var ael1 = document.getElementById(id);
        let wkid = $event.target.value
        localStorage.setItem('al1active', $event.target.value)
        let getmenuid = localStorage.getItem('al1active')
        if (getmenuid === wkid) {
          ael1.classList.add("active")
        }
        
      }catch(error) {
        console.log(error)
      }
    },
    onChange($event) {
      let getVal = $event.target.value;
      axios.get(`getsearchdata/${getVal}`).then(
      response => {
        // this.filteredList = response.data
        this.items = response.data
      })
      .catch((error) => {
        console.log(error)
      })
    },

    closeModalNow($event){
      try {
        $('#staticBackdrop1').modal('hide');
    
      }catch(error) {
        console.log(error)
      }
    },

    setLangEn() {
      try {
        localStorage.setItem('lang', 'en');
        localStorage.setItem('langen', 'langen');
        this.$router.push('/')
        .then(() => {this.$router.go(0)})
      }catch(error) {
        this.$router.go();
      }
    },
    setLangFr() {
      try {
        localStorage.setItem('lang', 'fr');
        localStorage.setItem('langfr', 'langfr');
        this.$router.push('/')
        .then(() => {this.$router.go(0)})
      }catch(error) {
        this.$router.go();
      }
    },

    //1. from event get the id as an integer. 
    //2. get the element and pass the id to it. store the id in localStorage
    //3. add class but remove existing name class before adding the new class
    //4. finally, get data based on selected id.
    getId($event) {
      let id = parseInt($event.target.id);
      var ael = document.getElementById(id);
      localStorage.setItem('al1active', parseInt($event.target.value))
   
      let x = document.getElementsByClassName("menulevel1");
      if(x.length > 0) 
        { 
          x[0].classList.remove("menulevel1"); 
        }
        ael.classList.add("menulevel1")
   

      this.divsectwo = [];
      axios.get(`getdivsecone/${id}`).then(
      response => {
        this.divsecone = response.data.data
      })
      .catch((error) => {
        console.log(error)
      })
    },

    getsectwoId($event) {
      let id = parseInt($event.target.id);
      var ael1 = document.getElementById(id);
      localStorage.setItem('al2active', parseInt(id))
   
      let x1 = document.getElementsByClassName("menulevel2");
      if(x1.length > 0) 
        { 
          x1[0].classList.remove("menulevel2"); 
        }
        ael1.classList.add("menulevel2")



      axios.get(`getdivsectwo/${id}`).then(
      response => {
        this.divsectwo = response.data.data
        this.$router.push(`/${divsectwo}`)
        // console.log(response.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
      // console.log(id);
    },

    getsecthreeId($event){
      let id3 = $event.target.id
      var ael3 = document.getElementById(id3);
      localStorage.setItem('al3active', parseInt(id3))
   
      let x3 = document.getElementsByClassName("menulevel3");
      if(x3.length > 0) 
        { 
          x3[0].classList.remove("menulevel3"); 
        }
        ael3.classList.add("menulevel3")
    },


    noDispalay($event){
      $event.preventDefault()
    },

    async goToOpenMenu(id) {
      try{
        id = (event.target.id);
      await axios.get(`getds1link/${id}`).then(
      response => {
        var getmenu = response.data['ds1link']
        this.$router.push({name: `${getmenu}`})
        // console.log(response.data.data)
      })
      }catch(error) {
        console.log(error)
      }
    },
    async goToOpenMenuTwo(id){
      try{
        id = (event.target.id);
      await axios.get(`getds2link/${id}`).then(
      response => {
        var getmenutwo = response.data['ds2link']
        this.$router.push(`/${getmenutwo}`)
        this.$router.push({name:`${getmenutwo}`})
              // console.log(response.data.data)
      })
      }catch(error) {
        console.log(error)
      }
    },
  },

mounted() {

  let mycid = localStorage.getItem('al1active')
  let mylang = localStorage.getItem('lang')

  try {
    if(mycid != "") {
    let x1 = document.getElementsByClassName(mycid);
    x1.classList.add("menulevel1")
  }
  }catch(error){
    console.log(error)
  }
  axios.get('menualldesc/').then(
    response => {
      this.menualldesc = response.data
    })
    .catch((error) => {
      console.log(error)
    })

  axios.get('mainmenu/').then(
    response => {
      this.mainmenu = response.data
    })
    .catch((error) => {
      console.log(error)
    })

    axios.get('divsecone/').then(
    response => {
      this.divseconeinfo = response.data
    })
    .catch((error) => {
      console.log(error)
    })

    axios.get('divsectwo/').then(
    response => {
      this.divsectwoinfo = response.data
    })
    .catch((error) => {
      console.log(error)
    })
  }

}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
nav{
  position: fixed;
  z-index: 99;
  width: 100%;
  background-color: #252525;
}
/*Top navigation wrapper*/
nav .wrapper{
  position: relative;
  width: 100%;
  height: 70px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
}
.wrapper .logo a{
  color: #f2f2f2;
  font-size: 14px;
  font-weight: 100px;
  text-decoration: none;
}
.wrapper .nav-links{
  display: inline-flex;
}
.imglogo {

}
.nav-links li{
  list-style: none;
}
.nav-links li a{
  color: #f2f2f2;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
  padding: 9px 15px;
  transition: all 0.3s ease;
}
.nav-links li a:hover{
  background: rgb(27, 27, 27);
  color: skyblue;
}
.nav-links .mobile-item{
  display: none;
}
.nav-links .drop-menu{
  position: absolute;
  background: black;
  width: 180px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px black;
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box{
  transition: all 0.3s ease;
  top: 70px;
  opacity: 1;
  visibility: visible;
}
.drop-menu li a{
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
}
.mega-box{
  position: absolute;
  left: 0;
  width: 100%;
  top: 85px;
  opacity: 0;
  visibility: hidden;
}
.mega-box .content{
  background: black;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 6px 10px black;
}
.mega-box .content .row{
  width: calc(25% - 30px);
  line-height: 45px;
}
.content .row img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content .row header{
  color: #f2f2f2;
  font-size: 20px;
  font-weight: 500;
}
.content {
  padding: 40px;
}
.content .row .mega-links{
  margin-left: -40px;
  border-left: 1px solid rgba(255,255,255,0.09);
}
.row .mega-links li{
  padding: 0 20px;
}
.row .mega-links li a{
  padding: 0px;
  padding: 0 20px;
  color: #d9d9d9;
  font-size: 14px;
  display: block;
}
.row .mega-links li a:hover{
  color: skyblue;
}
.wrapper .btn{
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.wrapper .btn.close-btn{
  position: absolute;
  right: 30px;
  top: 10px;
}
.iconimage {
  width: 30px;
}
.iconimage:hover {
  width: 35px;
  background-color: orange;
  cursor: pointer;
  transition: ease-out;
  transition-duration: 2s;
}
.iconimage {
  visibility: hidden;
}
.offcanvas {
  padding: 20px;
  background-color: black;
}
.btn-close{
  background-color: white;
  font-size: 20px;
}
.fa-magnifying-glass {
  width: 10px;
}
.formatrow1, .formatrow4 {
  max-width: 30%;
  overflow-wrap: break-word;
  padding: 10px;
}
.formatrow2, .formatrow3 {
  width: 20%;
}
.formatrow1 {
  padding-right: 20px;
}
.topcaption {
  width: 100%;
}
.fonticonformat {
  width: 60px;
  margin-left: 10px;
  color: white;
}
/*------------------ Accordion----------------------*/
.accordioninner {
  background-color: #040D12;;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  border-bottom: solid thin white;
}

.active, .accordioninner:hover {
  background-color: #040D12;
}

.accordioninner:after {
  content: '\002B';
  color: white;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "\2212";
}

.panel {
  padding: 0 18px;
  background-color: #040D12;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.outeraccordion {
  margin-top: 30px;
  margin-bottom: 30px;
}
.offcanvas-body {
  margin-bottom: 20px;
}
.accordion-button {
  height: 50px;
  padding: 10px;
  background-color: black;
  color: white;
}
.panel, .forminnerbody {
  background-color: 040D12;
  color: whitesmoke;
}
.forminnerbody {
  background-color: #040D12;
  padding: 10px;
}
.extendedformatting{
  padding: 20px;
  background-color: #040D12;
  font-size: 14px;
}
.formatlinks {
  padding: 20px;
  text-decoration: none;
}
.formatlinks .linksfmt {
color: white;
font-size: 14px;
}
.formatfa {
  font-size: 20px; 
  color: white;
}
.formatfa:hover {
  transition-duration: 0.5s;
  transform: scale(1.5);
  transition: transform .2s;
  cursor: pointer
}

.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.accordion-button:not(.collapsed)::after {
  filter: invert(50%);
}
.contentformat {
  color: whitesmoke;
}
nav input{
  display: none;
}
.imglogo {
  width: 200px;
  margin-left: 120px;
}
.formatfside {
  font-size: 12px;
  line-height: 1.3rem;

}
.formatfside1 {

}
.setEnLang {
  color: orangered;
}
.setFrLang {
  color: orangered;
}
.menulevel2 {
  border-bottom: solid red thin;
  background-color: rgb(27, 27, 27);
}
.menulevel1 {
  border-bottom: solid skyblue thin;
  background-color: rgb(27, 27, 27);
}
.menulevel3 {
  background-color: rgb(27, 27, 27);
  position: relative;
}

/*------------------ Accordion end----------------------*/
@media screen and (max-width: 600px) {
  .iconimage {
    visibility: visible;
    margin-right: 20px;
  }
.wrapper .navbar-brand .imglogo {
  width: 170px;
  margin-left: -5px;
  padding-left: 30px;
}
.d-flex {
  float: right; padding-right: 70px;
}
.offcanvasvisibility {
  visibility: visible;
}
}
@media screen and (max-width: 600px) {
  .offcanvasvisibility {
    visibility: visible;
  }
  .d-flex {
    float: right; padding-right: 100px;
  }
}

@media screen and (min-width: 768px) {
  .offcanvasvisibility {
    visibility: visible;
  }
  .d-flex {
    float: right; padding-right: 100px;
  }
  }

  @media screen and (max-width: 768px) {
    .d-flex {
      float: right; padding-right: 100px;
    }
    }


@media screen and (min-width: 970px) {
.offcanvasvisibility {
  visibility: hidden;
}
}
@media screen and (max-width: 970px) {
  .wrapper .btn{
    display: block;
  }
  .wrapper .nav-links{
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    top: 0;
    left: -100%;
    background: #003;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0,0,0,0.18);
    transition: all 0.3s ease;
  }
  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #003;
  }
  ::-webkit-scrollbar-thumb {
    background: #3A3B3C;
  }

  .menu-btn{
    padding-right: 20px;
    
  }
  .nav-links li{
    margin: 15px 10px;
  }
  .nav-links li a{
    padding: 0 20px;
    display: block;
    font-size: 20px;
  }
  .nav-links .drop-menu{
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }
  

  .nav-links .desktop-item{
    display: none;
  }
  .nav-links .mobile-item{
    display: block;
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .nav-links .mobile-item:hover{
    background: #3A3B3C;
  }
  .drop-menu li{
    margin: 0;
  }
  .drop-menu li a{
    font-size: 18px;
  }
  .mega-box{
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  .mega-box .content{
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }
  .mega-box .content .row{
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255,255,255,0.08);
  }
  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2){
    border-top: 0px;
  }
  .content .row .mega-links{
    border-left: 0px;
    padding-left: 15px;
  }
  .row .mega-links li{
    margin: 0;
  }
  .content .row header{
    font-size: 19px;
  }
}



@media screen and (min-width: 1200px) {
  .d-flex {
    float: right; padding-right: 200px;
  }
  .formatultopmenu {
    margin-left: 150px;
  }
  .offcanvasvisibility {
    visibility: hidden;
  }


}
</style>