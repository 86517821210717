<template>
    <div class="carousel">
      <div class="inner" ref="inner" :style="innerStyles">
        <div class="card p-3 innerdiv imageformat zoom" v-for="card in cards" :key="card" :style="`background-image: url(${card.imageUrl}); margin-left: 40px;`">
            <div>
                <h4>Testing inner data</h4>
                {{ card.caption }}
            </div>
        </div>
      </div>
    </div>
    <button @click="prev">prev</button>
    <button @click="next">next</button>
  </template>
  
  <script>
  export default {
    data () {
      return {
        // cards: [1, 2, 3, 4, 5, 6, 7, 8],
        innerStyles: {},
        step: '',
        transitioning: false,
        cards: [
        {
          imageUrl: 'https://hq.nexgbits.com/media/imgupload/slide-1.jpg',
          caption: 'Photo by 1'
        },
        {
          imageUrl: 'https://hq.nexgbits.com/media/imgupload/slide-2.jpg',
          caption: 'Photo by 2'
        },
        {
          imageUrl: 'https://hq.nexgbits.com/media/imgupload/slide-1.jpg',
          caption: 'Photo by 3',
        },
        {
          imageUrl: 'https://hq.nexgbits.com/media/imgupload/slide-2.jpg',
          caption: 'Photo by 4'
        },
        {
          imageUrl: 'https://hq.nexgbits.com/media/imgupload/slide-2.jpg',
          caption: 'Photo by 5'
        }
      ]
      }
    },
  
    mounted () {
      this.setStep()
      this.resetTranslate()
    },
  
    methods: {
      setStep () {
        const innerWidth = this.$refs.inner.scrollWidth
        const totalCards = this.cards.length
        this.step = `${innerWidth / totalCards}px`
      },
  
      next () {
        if (this.transitioning) return
  
        this.transitioning = true
  
        this.moveLeft()
  
        this.afterTransition(() => {
          const card = this.cards.shift()
          this.cards.push(card)
          this.resetTranslate()
          this.transitioning = false
        })
      },
  
      prev () {
        if (this.transitioning) return
  
        this.transitioning = true
  
        this.moveRight()
  
        this.afterTransition(() => {
          const card = this.cards.pop()
          this.cards.unshift(card)
          this.resetTranslate()
          this.transitioning = false
        })
      },
  
      moveLeft () {
        this.innerStyles = {
          transform: `translateX(-${this.step})
                      translateX(-${this.step})`
        }
      },
  
      moveRight () {
        this.innerStyles = {
          transform: `translateX(${this.step})
                      translateX(-${this.step})`
        }
      },
  
      afterTransition (callback) {
        const listener = () => {
          callback()
          this.$refs.inner.removeEventListener('transitionend', listener)
        }
        this.$refs.inner.addEventListener('transitionend', listener)
      },
  
      resetTranslate () {
        this.innerStyles = {
          transition: 'none',
          transform: `translateX(-${this.step})`
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .carousel {
    width: 100%;
    overflow: hidden;
  }
  
  .inner {
    transition: transform 0.2s;
    white-space: nowrap;
    padding: 50px;
  }
  
  .card {
    width: 600px;
    height: 600px;
    margin-right: 10px;
    display: inline-flex;
    padding: 40px;
  
    /* optional */
    height: 40px;
    background-color: #39b1bd;
    color: white;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
  }
  
  /* optional */
  button {
    margin-right: 5px;
    margin-top: 10px;
  }
  .zoom {
    transition: transform .90s;
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }
  .zoom:hover {
    -ms-transform: scale(.5); /* IE 9 */
    -webkit-transform: scale(.5); /* Safari 3-8 */
    transform: scale(.9); 
  }
  .imageformat{
    width: 100%; 
    border-radius: 5px;
    width: 300px; 
    height: 400px; 
  }
.innerdiv {
 width: 400px;
 height: 500px;
 padding: 40px;
}

/*===================MEDIA SCREENS TAMPLATES===============*/
     /* Extra small devices (phones, 600px and down) */
     @media only screen and (max-width: 600px) {
    .card {
        width: 100%;
        padding: 20px;
        margin-left: 2px;
    }
        /*.splide__arrow--next{
          margin-right: 220px;
        }*/
      }
    
    
      /* Small devices (portrait tablets and large phones, 600px and up) */
      @media only screen and (min-width: 600px) {
   
       /* .splide__arrow--next{
          margin-right: 380px;
        }*/
    
      }
      
      /* Medium devices (landscape tablets, 768px and up) */
      @media only screen and (min-width: 768px) {

       /* .splide__arrow--next{
          margin-right: 520px;
        }*/
      }
      
      /* Large devices (laptops/desktops, 992px and up) */
      @media only screen and (min-width: 992px) {
   
        /*.splide__arrow--next{
          margin-right: 680px;
        }*/
      }
      
      /* Extra large devices (large laptops and desktops, 1200px and up) */
      @media only screen and (min-width: 1200px) {
    
        /*.splide__arrow--next{
          margin-right: 800px;
        }*/
      } 
      
      /* Extra large devices (large laptops and desktops, 1200px and up) */
      @media only screen and (min-width: 1400px) {
     
       /* .splide__arrow--next{
          margin-right: 880px;
        }*/
        } 
    
      /* Extra large devices (large laptops and desktops, 1200px and up) */
      @media only screen and (min-width: 1600px) {
    
        } 
  </style>