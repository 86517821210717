<template>
<!-- <section class="slidecontainer" :class="{swiperthemecss: swipertheme==='swiperthemecss', swiperthemecss1: swipertheme==='swiperthemecss1'}"> -->
  <div class="container">
    <div v-html="slidertextinfo" class="swipertextformat"></div>
    
      <swiper
          ref="{swiperRef}"
          :slidesPerView="3"
          :centeredSlides="false"
          :spaceBetween="30"
          @swiper="onSwiper"
          :loop="true"
          :navigation="true"
          style="background-color: #003;"
          :breakpoints="{
            '320': {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            '640': {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            '768': {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            '992': {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }"
          :modules="modules"
          
          
          class="mySwiper"  
          
        >
          <swiper-slide class="card shadow formatslide" v-for="slide in SliderData" :key="slide.slideid">
            <img :src="`${slide.slideimage}`" class="slideimage"/>
            <div v-html="slide.slidetext"></div>
          </swiper-slide>
        </swiper>
       

  <p class="append-buttons"></p>
 
  </div>
  <!-- :pagination="{
    type: 'fraction',
  }" -->
 <!-- </section> -->
</template>
<script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
  import 'swiper/css';

  import 'swiper/css/pagination';
  import 'swiper/css/navigation';


  // import required modules
  import { Pagination, Navigation } from 'swiper/modules';

  export default {
    props: ['SliderData','swipertheme', 'slidertextinfo', 'slidebgtheme'],
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      return {
        modules: [Pagination, Navigation],
      };
    },
  };
</script>

<style scoped>
.slidecontainer {
 background-color: whitesmoke;
 padding: 20px;
 padding-bottom: 100px;
}
.swiper {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: white;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: white;
  height: 620px;
 
  color: #003;
  border-radius: 5px;
 

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 660px;
  margin-top: 60px;
  background-color: white;
}
.append-buttons {
  text-align: center;
  margin-top: 20px;
  color: orangered;
}

.swiper-pagination {
  font-size: 18px;
  font-weight: bold;
}
.swipertextformat {
  text-align: center;
  color: rgb(182, 177, 177);
  padding-top: 20px;
}
.slideimage {
  max-width: 50%;
  max-height: 20%;
}
.formatslide {
  cursor: pointer;
  opacity: 0.6;
}
.formatslide:hover {
  margin-top: 20px;
  opacity: 0.3;
}
/*===================MEDIA SCREENS TAMPLATES===============*/
 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {

 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {

 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {

 }
 
 @media only screen and (min-width: 800px) {
  
 }

 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {

 }


 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
  
 } 
</style>