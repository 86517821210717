<template>

<section class="footer"  :class="{footerdiv: footertheme === 'footerdiv'}" style="">
  <!-- ======= Footer ======= --> 
  <div class="footercontainer">
    <div style="text-align: center; height: auto;  margin-bottom: 80px;">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6">
          <div>JOIN OUR TEAM</div>
          <div>Release your potentials</div>
          <btn style="border-radius: 30px; padding: 20px; background-color: #252525; border: solid thin white; color: white; margin-top: 20px;" class="btn">
            Explore our career portal</btn>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6">
          <div>REGISTER TO OUR NEWSLETTER</div>
          <div>Get our latest news</div>
          <btn style="border-radius: 30px; padding: 20px; background-color: #252525; border: solid thin white; color: white; margin-top: 20px;" class="btn">
            Learn more about us</btn>
        </div>
      </div>
    

      <span style="padding-bottom: 20px;"><hr style="margin-top: 130px;" /></span>


    <div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-3" v-for="dcat in footercatinfo">
          <div class="footercaption">
            {{dcat.fcodedesc}}
          </div>
          <div v-for="dcatdata in footerdatainfo">
            <div v-if="dcatdata.fdataref===dcat.fcodeid">
              <ul>
                <li class="footerdata"><router-link class="rlinkcss" :to="{path: dcatdata.fdatalinkpath }"> {{ dcatdata.fdatadesc}}</router-link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  
  
  
 
  
    <a href="#" class="back-to-top d-flex align-items-center justify-content-center"></a>
  </div>
  <div class="container-fluid lastfooter">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-2">
        <h3><img class="imglogo" src="@/assets/logo1.png"  style="margin-top: 10px; padding: 20px;" /></h3>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8">
        <div class="copyright">
          &copy; Copyright <strong><span>Nexgbits</span></strong>. All Rights Reserved
        </div>
        <div>
          <ul class="formatul">
            <li class="formatli">privacy notice</li>
            <li class="formatli">Cookies policy</li>
            <li class="formatli">Disclaimer</li>
            <li class="formatli">Security policy</li>
            <li class="formatli">Terms of use</li>
          </ul>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-2">
        <ul class="siconsul">
          <li class="siconsli"><a href="#" ></a></li>
          <li  class="siconsli"><a href="#" ><i class="fa-brands fa-square-facebook sicons"></i></a></li>
          <li  class="siconsli"><a href="#" ><i class="bx bxl-instagram sicons"></i></a></li>
          <li  class="siconsli"><a href="#"><i class="bx bxl-skype sicons"></i></a></li>
          <li  class="siconsli"><a href="#" ><i class="fa-brands fa-linkedin sicons"></i></a></li>
        </ul>
        
      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div class="modal fade" id="termsofuse" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Terms of Use</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div v-html="termsofservices"></div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="privacy" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Privacy</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div v-html="nbprivacy"></div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>


<div class="modalclass" id="showHide">
  <input type="hidden" @click="openModal = true" />
  <div v-if="openModal">
    <!-- <div class="modal-background"></div> -->
    <div class="modal-content">
      <div class="card">
        <div class="card-header" style="background-color: red; color: white; font-weight: bold">
          Privacy
        </div>
        <div class="card"  style="background-color: #003; color: white;">
          <div class="card-body" >
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-10">
                <div v-html="popmessage"></div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2">
                <button class="card-link btn btn-primary" @click="showHideModel()">Yes</button>
                <button class="card-link btn btn-success" @click="hideModal()">No</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { useToast } from "vue-toastification";


export default {
    name: 'FooterPage',
    props: ['footertheme'],
    data() {
        return {
            subscriberemail: '',
            subscribedemail: '',
            ourservices: [],
            usefullinks: [],
            nbaddress: '',
            nbaddress2: '',
            nbaddress3: '',
            nbphone: '',
            nbemail: '',
            nbjoinletter: '',
            nbsubscriptiontext: '',
            openModal: true,
            termsofservices: '',
            nbprivacy: '',
            popmessage: '',


           footercatinfo: [],
           footerdatainfo: [],
        }
    },
  
  methods: {
    dateTime(value) {
      return moment(value).format('YYYY-MMMM-DD');
      },
    
    showHideModel() {
      let elx = document.getElementById('showHide');
      elx.style.visibility = "hidden";
      this.openModal = false
      localStorage.setItem('openModel', false)
    },
    hideModal(){
      let elx = document.getElementById('showHide');
      elx.style.visibility = "hidden";
      this.openModal = false
      localStorage.setItem('openModel', false)
    },
   async registerSubscriber() {
    if (this.subscriberemail === "") {
      if(this.subscriberemail === '') {
              let e = "Email is required."
              const toast = useToast();
              toast.error(e, {
              timeout: 6000
                });
              return;
    }
      }
   
    await axios.get(`getsubscriberemail/${this.subscriberemail}`).then(
        response => {
          let vm = this
          vm.subscribedemail = response.data[0].subscriberemail
          if(this.subscriberemail === vm.subscribedemail) {
                let e1 = 'Email exists'
                const toast1 = useToast();
                toast1.error(e1, {
                timeout: 6000
                });

            return;
          }
      else {
        const datetime = new Date()
        axios.post('subscribers/', {
        subscriberemail: vm.subscriberemail,
        subscriberdate: moment(datetime),
        unsubscribedate: moment('1900-01-01')
        }).then(response => {
          if (response.status === 201) {
    
              let e = "Record posted successfully."
              const toast = useToast();
              toast.success(e, {
              timeout: 6000
              });
            }
            })
            .catch(e => {
            const toast = useToast();
            toast.error(e, {
            timeout: 6000,
          })
        })
      }

      }).catch((response) => {
            console.log(response)
            })
    
    }
  },

  async mounted() {
   let opm = localStorage.getItem("openModel")
   if(opm) {
    let localstel = document.getElementById('showHide');
    localstel.style.visibility = "hidden";
    this.openModal = false
   }

    axios.get('nbaddressdetails/1').then(
            response => {
                this.nbaddress = response.data.nbaddress;
                this.nbaddress2 = response.data.nbaddress2;
                this.nbaddress3 = response.data.nbaddress3;
                this.nbphone = response.data.nbphone;
                this.nbemail = response.data.nbemail;
                this.nbjoinletter = response.data.nbjoinletter;
                this.nbsubscriptiontext = response.data.nbsubscriptiontext;
            })
            .catch((error) => {
            console.log(error)
            })
  
    await axios.get('usefullinkscreateview/').then(
    response => {
        this.usefullinks = response.data;
    })
    .catch((error) => {
    console.log(error)
    })

    await axios.get('ourservicescreateview/').then(
    response => {
        this.ourservices = response.data;
    })
    .catch((error) => {
    console.log(error)
    })

    await axios.get('termsofservice/').then(
    response => {
        this.termsofservices = response.data[0].nbtermofservice
        this.nbprivacy = response.data[0].nbprivacy
        this.popmessage = response.data[0].popmessage
    })
    .catch((error) => {
    console.log(error)
    })

    await axios.get('getfootercategory/').then(
    response => {
        this.footercatinfo = response.data
    })
    .catch((error) => {
    console.log(error)
    })

    await axios.get('getfooterdata/').then(
    response => {
        this.footerdatainfo = response.data
    })
    .catch((error) => {
    console.log(error)
    })
     
  }
  }
</script>
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
body {
  background-color: WHITE;
  width:100%;
  height:100vw;
  padding:0;
  margin:0;
}

.footer{
  color: white;
  font-size: 17px;
  opacity: 0.99;
  bottom: 0;
  padding-bottom: 0;
  position: relative;
  padding-top: 100px;
  line-height: 34px;
  background-color: #252525;
  
}
.footerdata {
  text-align: left;
  font-size: 14px;
}

.footerdata .rlinkcss {
  color: white;
}
.footercaption{
  text-align: left;
  margin-left: 30px;
  font-size: 16px;
}
.indenttextlinksft {
  color: white;
}

ul {
  list-style: none;
}

.faiconscss{
  font-size: 30px;
  padding-left: 5px;
  transition: all 0.2s ease-in-out;
  border: solid thin #003;
  border-radius: 5px;
}
ul li {
  padding-top: 10px;
}

.lastfooter {
 background-color: black;
 height: auto;
}
.formatinnerfooter {
  padding-top: 25px;
}
.imglogo {
  margin-left: -14px;
  width: 200px;
  opacity: 09;
}
.indenttextlinksft {
  text-decoration: none;
}
.tmuse {
  cursor: pointer;
}
.modal-header, .modal-footer {
  background-color: #003;
  color: white;
}
.btn-close {
  background-color: white;
}
.modalbottom { 
  position: absolute;
  bottom: 10vh
}
.modalclass {
  position:fixed;
  bottom:0;
  width:100%;
  height:230px;
  background-color: #003;
}
.copyright {
  font-size: 12px;
  margin-left: 30px;
  padding-top: 5px;
}
.formatul .formatli {
  display: inline-block;
  margin-left: 10px;
  font-size: 12px;
}
.siconsul .siconsli {
  display: inline-block;
  margin-left: 10px;
  font-size: 16px;
  margin-top: 14px;
}
.sicons {
  font-size: 24px;
}
@media only screen and (max-width: 550px) {
  ul {
    margin-left: 10px;
  }
  .footersection{
    left: 0;
    bottom: 0;
  
    color: white;
    font-size: 16px;
    background-image: url("../assets/slide-5.jpg");
    opacity: 0.99;
    bottom: 0; 
    min-height: 100vh;
    margin-top: auto;
  }
}
@media only screen and (max-width: 600px) {
  ul {
    margin-left: 10px;
  }
  .modalclass {
    height:380px;
  }
  .footercontainer {
    padding-left: 40px;
    padding-right: 40px;
  }
  .imglogo {
    margin-left: 10px;
  }
}
@media only screen and (min-width: 600px) {
  .modalclass {
    height:310px;
  }
  .footercontainer {
    padding-left: 100px;
    padding-right: 100px;
  }
  .imglogo {
    margin-left: 15px;
  }
  }
@media only screen and (min-width: 768px) {
  .modalclass {
    height:250px;
  }
  }
@media only screen and (max-width: 992px) {
.footer {
  min-height: 100vh;
}
.modalclass {
  height:180px;
}
}
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
  .modalclass {
    height:220px;
  }
  .footercontainer {
    padding-left: 140px;
    padding-right: 140px;
  }
  .lastfooter {
    padding-left: 140px;
    padding-right: 140px;
  } 
}

 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1600px) {
  .footercontainer {
    padding-left: 240px;
    padding-right: 240px;
  }
  .lastfooter {
    padding-left: 200px;
    padding-right: 200px;
  }
 }
</style>