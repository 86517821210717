<template>
    <section :class="{twoseccss: twosectheme==='twoseccss', twoseccss1: twosectheme1==='twoseccss1'}">
        <div class="datacontainer">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 ">
              <div v-html="lefttext" class="leftside"></div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 ">
              <div v-html="righttext" class="rightside"></div>
            </div>
          </div>
        </div>
      </section>
</template>

<script>

export default {
  props: ['lefttext', 'righttext', 'twosectheme', 'twosectheme1']
}
</script>

<style scoped>
.titletext {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
  font-size: 54px;
  font-weight: bold;
  color: grey
}
.leftside {
  margin-top: 100px;
  overflow-x: scroll;
}
/*==============MEDIA QUERIES=====================*/
@media only screen and (max-width: 600px) {
  .datacontainer {
    padding-left: 30px;
    padding-right: 30px;
  }
}

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
  .datacontainer {
    padding-left: 60px;
    padding-right: 60px;
  }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
  .datacontainer {
    padding-left: 60px;
    padding-right: 120px;
  }
 }

 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
  .datacontainer {
    padding-left: 100px;
    padding-right: 40px;
  }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1400px) {
  .datacontainer {
    padding-left: 100px;
    padding-right: 100px;
  }
}

  /* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {
  .datacontainer {
    padding-left: 220px;
    padding-right: 220px;
  }
}
</style>