<template>
   <router-view  :key="$route.fullPath"/>
   

</template>
<script>

export default {
  mounted() {
        const basePicURL = "http://192.168.2.208:8000";

        return basePicURL;
  }
}
</script>
<style>
@import "@/assets/layout.css";
@import '@/assets/css/pannellium-2.5.css';
@import '@/assets/css/video-js.css';


/* For desktop: */
.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}


body {
  background-color: 252525;
}
.setbgcolorgrey {
background-color: grey;
}
.setbgcolorwhite {
background-color: whitesmoke;
}
.setbgcolorblack{
background-color: black;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
}
</style>
