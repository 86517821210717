<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4">
        <div class="setscroll">
          <ul id="myUL" @click="toggleMenu()">
            <li v-for="(tcap, index) in topcaption">
                <span class="caret datacss">{{ tcap.artcatname}}</span>
              
              <ul class="nested" @click="toggleMenu()">
                <li v-for="tdata in topdata">
                  <!-- @click.prevent="getClickedId($event, $event.target.value=tdata.artcatdfkid)" -->
                  <span class="datacss" v-if="tdata.artcatdatafkid == tcap.artcatid">
                    <router-link :to="{path: `/blogs/overview/${tdata.artcatdfkid}`}" @submit.prevent>{{tdata.artcdatadesc}}</router-link>
                  </span>
                </li>
                <li v-for="l1c in level1caption" @click="toggleMenu()">
                  <span v-if="l1c.artl1capfkid == tcap.artcatid" class="caret">
                    <span class="datacss">
                      {{ l1c.artl1desc }}
                    </span>
                  </span>
                  <ul class="nested" @click="toggleMenu()">
                    <!-- @click.prevent="getClickedId($event, $event.target.value=l1dat.artcpfkid)" -->
                    <li v-for="l1dat in level1data" > 
                      <span class="datacss" v-if="l1dat.artl1capfkid == l1c.artl1cid">
                        <router-link :to="{path: `/blogs/overview/${l1dat.artcpfkid}`}" @submit.prevent>{{l1dat.artl1datadesc}}</router-link></span>
                    </li>
                    <li v-for="l2c in level2caption" @click="toggleMenu()">
                        <span v-if="l2c.artl2capfkid == l1c.artl1cid"  class="caret">
                            <span class="datacss">{{ l2c.artl2desc}}</span>
                        </span>
                      <ul class="nested" @click="toggleMenu()">
                        <!-- @click.prevent="getClickedId($event, $event.target.value=l2dat.artd2fkid)" -->
                        <li v-for="l2dat in level2data">
                          <span v-if="l2dat.artl2datacfkid == l2c.artl2cid"  class="datacss">
                            <router-link :to="{path: `/blogs/overview/${l2dat.artl2datacfkid}`}" @submit.prevent>{{l2dat.artl2datadesc}}</router-link>
                          </span>
                        </li>
                
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8">
        <div>
          <div v-html="articletitle"></div>
        </div>
        <div v-for="artdata in articledata">
          <div v-html="artdata.artfulltext"></div>
        </div>
      </div>
    </div>
  </div>
    
</template>

<script>
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()

export default{

    // props: ['topcaption', ],
    data() {
        return {
        topcaption: [],
        topdata: [],
        level1caption: [],
        level1data: [],
        level2caption: [],
        level2data: [],
        articledata: [],
        articletitle: "",
        }
    },
    computed: {
    params:function () {
      return this.$route.params;
    }
  },
  async mounted() {

   await axios.get('getarticlecat/').then(
      response => {
        this.topcaption = response.data
      }).catch((error) => {
          console.log(error)
        })
  
    await axios.get('getarticlecatdata/').then(
    response => {
      this.topdata = response.data
    }).catch((error) => {
          console.log(error)
        })

    await axios.get('getarticlel1caption/').then(
    response => {
      this.level1caption = response.data
    }).catch((error) => {
          console.log(error)
        })
  
  await axios.get('getarticlel1data/').then(
    response => {
      this.level1data = response.data
    }).catch((error) => {
          console.log(error)
        })
    
  await axios.get('getarticlel2caption/').then(
    response => {
      this.level2caption = response.data
    }).catch((error) => {
          console.log(error)
        })    
  
  await axios.get('getarticlel2data/').then(
    response => {
      this.level2data = response.data
    }).catch((error) => {
          console.log(error)
        })  
  
 
  let id = parseInt(this.$route.params.id)
  if(id === 0 || id === '' || id === 'NaN') {
    id = 1
  }
  axios.get(`getarticle/${id}`).then(
    response => {
      this.articledata = response.data
      this.articletitle = response.data[0].artdesc
    }).catch((error) => {
          console.log(error)
        }) 
        
    // this.expandAll()
   

  },
  methods: {
   toggleMenu() {
    var toggler = document.getElementsByClassName("caret");
    var i;
    for (i = 0; i < toggler.length; i++) {
          toggler[i].addEventListener("click", function() {
            this.parentElement.querySelector(".nested").classList.toggle("active");
            this.classList.toggle("caret-down");
          });
        }
   }
   
  //  expandAll(){
  //   var toggler = document.getElementsByClassName("caret");
  //   var i;
  //   const nest = [...document.querySelectorAll(".nested")]
  //       const expandAll = document.getElementById("myUL")
  //       toggler.forEach(d => d.addEventListener('load', e => {
  //         e.target.parentElement.querySelector(".nested").classList.toggle("active");
  //         e.target.classList.toggle("caret-down");
  //       }))

        
  //         if (nest.every(d => !d.classList.contains('active'))) {
  //           toggler.forEach(t => t.classList.add('caret-down'))
  //           nest.forEach(n => n.classList.add('active'))
  //         } else {
  //           toggler.forEach(t => t.classList.remove('caret-down'))
  //           nest.forEach(n => n.classList.remove('active'))
  //         }
  //  }
  },

  setup(){
  let carets = $('.caret').on('click', e => {
  let caret = $(e.target);

  // display the clicked item
  caret.toggleClass('caret-down');
  caret.closest('li').children('.nested').toggleClass('active');

});

$("#xyz").addClass('caret-down bg');
//get closest li -> not first ul add some class to other
$("#xyz").closest('li').find('ul.nested:not(:first)').addClass("unselect")
//get closest li-> find ul ->show that
$("#xyz").closest('li.outer').find("ul:not(.unselect)").toggleClass('active');
}
}
</script>
<style scoped>
ul, #myUL {
  list-style-type: none;
}

#myUL {
  margin: 0;
  padding: 0;
}

.caret {
  cursor: pointer;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
  font-size: 18px;
}

.caret::before {
  content: "\25B6";
  color: black;
  display: inline-block;
  margin-right: 6px;
}

.caret-down::before {
  -ms-transform: rotate(90deg); /* IE 9 */
  -webkit-transform: rotate(90deg); /* Safari */
  transform: rotate(90deg); 

}

.nested {
  display: none;
}

.active {
  display: block;
  font-size: 18px;

}
.datacss:hover {
  background-color: antiquewhite;
  cursor: pointer;
}
.setscroll {
  float:left;
  overflow-y: auto;
  height: 400px;
}
.setscroll::-webkit-scrollbar{
  width:15px;
  background-color:antiquewhite;
  }
  .setscroll::-webkit-scrollbar-track {
    background: rgb(233, 230, 230); 
  }
  
.setscroll::-webkit-scrollbar-thumb {
    background: grey;       
  }
</style>