<template>
  <section>
    <div class="contwrapper">
      <div class="row">
        <div :class="`col-xs-12 col-sm-12 col-md-${allcardmdcol} secright`" v-for="acf in allcardsinfo">
          <img :src="`${myImgUrl}${acf.cardimage}`" class="image"/>
          <div class="middle">
          <div class="text">{{acf.carddesc}}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import '../globalDeclare';


export default {
  props: ['allcardsinfo', 'allcardmdcol'],
  data() {
      return {
          myImgUrl: myUrlPath,
      }
      
  }
}
</script>


<style scoped>
.secright {
  position: relative;
}

.image {
  opacity: 0.7;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: relative;
  top: -24px;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.secright:hover .image {
  opacity: 0.3;
  background-color: #003;
  margin-top: -30px;
}

.secright {
  text-align: center;
}

.secright:hover .middle {
  opacity: 1;
}
.text {
  background-color: #575a59;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
  opacity: 0.6;
  font-size: 20px;
  font-weight: bold;

}
.contwrapper {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: rgb(15, 12, 12);
  padding-left: 20px;
  padding-right: 20px;
}
</style>