<template>
    <div :class="`col-xs-12 col-sm-12 col-md-${mdcolwidth} secright`" style="border-radius: 5px;">
        <img :src="`${myImgUrl}${sectionimage}`" class="image"/>
        <div class="middle">
        <div class="text">{{btmimagetext}}</div>
        </div>
    </div>
</template>

<script>
import '../globalDeclare';


export default {
    props: ['sectionimage', 'mdcolwidth', 'btmimagetext'],
    data() {
        return {
            myImgUrl: myUrlPath,
        }
        
    }
}
</script>


<style scoped>
.secright {
    position: relative;
    border-radius: 5px;
  }
  
  .image {
    opacity: 0.7;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
    border-radius: 5px;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: relative;
    top: -24px;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    border-radius: 5px;
  }
  
  .secright:hover .image {
    opacity: 0.3;
    background-color: #003;
    margin-top: -30px;
  }
 
  .secright {
    text-align: center;
  }
  
  .secright:hover .middle {
    opacity: 1;
  }
  .text {
    background-color: #575a59;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
    opacity: 0.6;
    font-size: 20px;
    font-weight: bold;

  }
</style>