import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AboutView from '@/views/AboutView.vue'
import NbBlogs from '@/views/NbBlogs.vue'
import Nbcareers from '@/views/NbCareers.vue'
import NbcustStories from '@/views/NbcustStories.vue'
import NbInsights from '@/views/NbInsights.vue'
import NbLeadership from '@/views/NbLeadership.vue'
import NbNewsroom from '@/views/NbNewsroom.vue'
import NbPlatforms from '@/views/NbPlatforms.vue'
import NbPolicies from '@/views/NbPolicies.vue'
import NbCsr from '@/views/NbCsr.vue'
import NbServices from '@/views/NbServices.vue'
import NbwreOverview from '@/views/NbwreOverview.vue'
import NbprojectMgmt from '@/views/NbprojectMgmt.vue'
import NbCloud from '@/views/NbCloud.vue'
import NbCybersecurity from '@/views/NbCybersecurity.vue'
import NbSustainability from '@/views/NbSustainability.vue'
import AboutUs from '@/views/AboutUs.vue'
import NbcloudAchitecting from '@/views/NbcloudAchitecting.vue'
import NbItsm from '@/views/NbItsm.vue'
import NbHrservices from '@/views/NbHrservices.vue'
import NbIsittraining from '@/views/NbIsittraining.vue'
import NbAzure from '@/views/NbAzure.vue'
import NbAws from '@/views/NbAws.vue'
import NbGoogle from '@/views/NbGoogle.vue'
//Login and Register Components
// import LoginView from '@/views/LoginView.vue'
// import RegisterView from '@/views/RegisterView.vue'
// import OtpVerify from '@/views/OtpVerify.vue'
// import ForgotPassword from '@/components/ForgotPassword.vue'
// import ResetPassword from '@/components/ResetPassword.vue'

// const locale = localStorage.getItem('lang');

  
//English Language Routes

const routes = [
  { path: '/', name: 'Home', component: HomeView,},
  { path: '/', name: 'Accueil', component: HomeView,},

  { path: '/about', name: 'About', component: AboutView,},
  { path: '/a-propos', name: 'A propos', component: AboutView,},
  
  // { path: '/about/:id', name: 'About', component: AboutView,},
  // { path: '/a-propos/:id', name: 'A propos', component: AboutView,},

  { path: '/blogs/overview', name: 'ourblog', component: NbBlogs,},
  { path: '/blogs/apercu', name: 'nousblog', component: NbBlogs,},


  { path: '/blogs/overview/:id', name: 'readblog', component: NbBlogs,},
  { path: '/blogs/apercu/:id', name: 'lireblogs', component: NbBlogs,},

  { path: '/blog/press-releases', name: 'ournews', component: NbBlogs,},
  { path: '/blog/press-releases', name: 'nousnouvelle', component: NbBlogs,},

  { path: '/careers/overview', name: 'careersov', component: Nbcareers,},
  { path: '/carrieres/apercu', name: 'carrieresov', component: Nbcareers,},

  { path: '/careers/canada', name: 'careers', component: Nbcareers,},
  { path: '/carrieres/canada', name: 'carrieres', component: Nbcareers,},

  { path: '/careers/cameroon', name: 'careerscmr', component: Nbcareers,},
  { path: '/carrieres/cameroun', name: 'carrieresca', component: Nbcareers,},

  { path: '/insights/policies-&-procedures', name: 'policies', component: NbPolicies,},
  { path: '/apercu/politiques-et-procedures', name: 'Strategies', component: NbPolicies,},

  { path: '/insights/our-interest/csr', name: 'csr', component: NbCsr,},
  { path: '/apercu/notre-interet/rse', name: 'rse', component: NbCsr,},

  { path: '/insights/our-interest/iot', name: 'iot', component: NbCsr,},
  { path: '/apercu/notre-interet/iot', name: 'niot', component: NbCsr,},

  { path: '/insights/our-interest/project-mgmt', name: 'pmgmt', component: NbprojectMgmt,},
  { path: '/apercu/our-interest/gestion-de-projet', name: 'frpmgmt', component: NbprojectMgmt,},

  { path: '/insights/our-interest/data-analytics', name: 'danalytics', component: NbprojectMgmt,},
  { path: '/apercu/notre-interet/analyse-de-donnees', name: 'frdanalytics', component: NbprojectMgmt,},

  { path: '/insights/our-interest/cloud', name: 'cloud', component: NbCloud,},
  { path: '/apercu/notre-interet/cloud', name: 'frcloud', component: NbCloud,},

  { path: '/insights/our-interest/cybersecurity', name: 'cybersecurity', component: NbCybersecurity,},
  { path: '/apercu/notre-interet/cybersecurite', name: 'frcybersecurity', component: NbCybersecurity,},

  { path: '/who-we-are/Overview', name: 'overview', component: AboutView,},
  { path: '/qui-nous-sommes/apercu', name: 'apercu', component: AboutView,},

  { path: '/who-we-are/leadership/mgmt-team', name: 'leadership', component: NbLeadership,},
  { path: '/qui-nous-sommes/leadership/equipe-de-gestion', name: 'frleadership', component: NbLeadership,},

  { path: '/who-we-are/about-us/partners', name: 'partners', component: AboutUs,},
  { path: '/qui-nous-sommes/a-propos-de-nous/partenaires', name: 'frpartners', component: AboutUs,},

  { path: '/who-we-are/about-us/sustainability', name: 'aboutsustainbility', component: NbSustainability,},
  { path: '/qui-nous-sommes/a-propos-de-nous/durabilite', name: 'fraboutsustainability', component: NbSustainability,},

  { path: '/who-we-are/about-us/diversity-equality-&-inclusion', name: 'dei', component: NbSustainability,},
  { path: '/qui-nous-sommes/a-propos-de-nous/diversite-egalite-et-inclusion', name: 'frdei', component: NbSustainability,},

  { path: '/who-we-are/about-us/mission-value-statements', name: 'missionstatement', component: AboutUs,},
  { path: '/qui-nous-sommes/a-propos-de-nous/declarations-de-mission-et-de-valeurs', name: 'frmissionstatement', component: AboutUs,},

  { path: '/what-we-do/services/cloud-architecting', name: 'carchitecting', component: NbcloudAchitecting,},
  { path: '//que-nous-faisons/services/architecture-cloud', name: 'frcarchitecting', component: NbcloudAchitecting,},

  { path: '/what-we-do/services/project-management', name: 'projectmgmt', component: NbprojectMgmt,},
  { path: '/que-nous-faisons/services/gestion-de-projets', name: 'frprojectmgmt', component: NbprojectMgmt,},

  { path: '/what-we-do/services/itsm', name: 'itsm', component: NbItsm,},
  { path: '/que-nous-faisons/services/itsm', name: 'fritsm', component: NbItsm,},

  { path: '/what-we-do/services/consulting', name: 'itconsulting', component: NbItsm,},
  { path: '/que-nous-faisons/services/consulting', name: 'fritconsulting', component: NbItsm,},

  { path: '/what-we-do/services/hr-services', name: 'ithr', component: NbHrservices,},
  { path: '/que-nous-faisons/services/service-hr', name: 'frithr', component: NbHrservices,},

  { path: '/what-we-do/services/is-it-training', name: 'isittraining', component: NbIsittraining,},
  { path: '/que-nous-faisons/services/est-ce-une-formation', name: 'frisittraining', component: NbIsittraining,},

  { path: '/what-we-do/products-&-platform/azure', name: 'azure', component: NbAzure,},
  { path: '/que-nous-faisons/produits-&-plateforme/azur', name: 'frazur', component: NbAzure,},

  { path: '/what-we-do/products-&-platform/aws', name: 'aws', component: NbAws,},
  { path: '/que-nous-faisons/produits-&-plateforme/aws-fr', name: 'fraws', component: NbAws,},

  { path: '/what-we-do/products-&-platform/google', name: 'google', component: NbGoogle,},
  { path: '/que-nous-faisons/produits-&-plateforme/google-fr', name: 'frgoogle', component: NbGoogle,},

  // { path: '/careers/job-posting/:jobid', name: 'jobposting', component: JobPosting,},
  // { path: '/carrieres/offre-d\'emploi/:jobid', name: 'offredeemploi', component: JobPosting,},
// //------------------------------------------
// { path: '/sustainability/social', name: 'OurSocial', component: OurSocial,
// meta: {
//   breadCrumb: [
//     {name: 'Home', link: '/' },
//     {name: 'OurSocial' }//crumb
//   ]
//  }
// },
// { path: 'sustainability/sociale', name: 'Sociale', component: OurSocial,
// meta: {
//   breadCrumb: [
//     {name: 'Home', link: '/' },
//     {name: 'Sociale' }//crumb
//   ]
//  }
// },
// //------------------------------------------
// { path: '/sustainability/governance', name: 'OurGovernance', component: OurGovernance,
// meta: {
//   breadCrumb: [
//     {name: 'Home', link: '/' },
//     {name: 'OurGovernance' }//crumb
//   ]
//  }
// },
// { path: '/sustainability/gouvernance', name: 'OurGovernance', component: OurGovernance,
// meta: {
//   breadCrumb: [
//     {name: 'Home', link: '/' },
//     {name: 'OurGovernance' }//crumb
//   ]
//  }
// },





//------------------Login Routes----------------------------------
  // { path: '/login', name: 'login', component: LoginView,
  // meta: {
  //   breadCrumb: [
  //     {name: 'Home', link: '/' },
  //     {name: 'Login' }//crumb
  //   ]
  // }
  // },
  // { path: '/register', name: 'register', component: RegisterView,
  // meta: {
  //   breadcrumb: [
  //     {name: 'Home', link: '/' },
  //     {name: 'Register' }//crumb
  //   ]
  // }
  // },
  // { path: '/otpverify', name: 'optverify', component: OtpVerify,
  // meta: {
  //   breadcrumb: [
  //     {name: 'Home', link: '/' },
  //     {name: 'Otpverify'},
  //   ]
  // }
  // },
  // { path: '/forgotpassword', name: 'forgotpassword', component: ForgotPassword,
  //   meta: {
  //     breadcrumb: [
  //       {name: 'Home', link: '/' },
  //       {name: 'Forgot Password' }//crumb
  //     ]
  //   }
  // },
  // { path: '/password-reset-confirm/:uidb64/:token', name: 'password-reset-confirm', component: ResetPassword,
  //   meta: {
  //     breadcrumb: [
  //       {name: 'Home', link: '/' },
  //       {name: 'Password Reset' }//crumb
  //     ]
  //   }
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

