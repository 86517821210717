<template>
  <div class="autocomplete">
    <input class="form-control form-control-lg txtsearch" 
    type="text" 
    placeholder="Please start typing...." 
    aria-label="Please start typing...."
    v-model="search"
    @input="onChange($event)"
    @keydown.down="onArrowDown"
    @keydown.up="onArrowUp"
    @keydown.enter="onEnter"
    >
 
    <ul
      v-show="isOpen"
      class="autocomplete-results"
    >
      <li
        v-if="isLoading"
        class="loading"
      >
        Loading results...
      </li>
      <li 
        v-else
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result.spshortsearchdesc)"
        class="autocomplete-result"
        :class="{ 'is-active': i === arrowCounter },  `li${i%2}`"
      >
      <router-link :to="{name: result.menulink}" @click="goToLink($event, $event.target.value='staticBackdrop')">
        <div>
          <p class="presult" >{{ result.spshortsearchdesc }}</p>
          <p v-html="result.spbriefdetails"></p>
       </div>
      </router-link>
   
        
        
      </li>
    </ul>
    <!-- <div class="item error" v-if="search && !items.length > 0">
      <span>No results found!</span>
   </div> -->
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";
export default {
  name: 'SearchAutocomplete',
  props: ['filteredList'],
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    isAsync: {
      type: Boolean,
      required: false,
      default: false,
    },
  },


   

  data() {
    return {
      search: '',
      results: [],
      isOpen: false,
      arrowCounter: -1
      // input: '',
    };
  },
  watch: {
    items: function (value, oldValue) {
      if (this.isAsync) {
        this.results = value;
        this.isOpen = true;
        this.isLoading = false;
      }
    }
  },
 
  methods: {
   goToLink($event) {
    try {
      this.$emit('goToLink', $event)
      let strlink = $event.target.value
      this.$router.push({name: strlink})
    
      }catch(error) {
        console.log(error)
      }
   },
     

    onChange($event) {
      this.results =  this.items.filter(item => item.spshortsearchdesc.toLowerCase().indexOf(this.search.toLowerCase()) >= 0);
      this.$emit('onChange', $event)
      if (this.isAsync) {
        this.isLoading = true;
      } else {
  
      this.isOpen = true;
      }
    },
    setResult(result) {
      this.search = result;
      this.isOpen = false;
    },
    handleClickOutside(event) {
        if (!this.$el.contains(event.target)) {
        this.arrowCounter = -1;
        this.isOpen = false;
      }
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {
      this.search = this.results[this.arrowCounter];
      this.arrowCounter = -1;
      this.isOpen = false;
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener('click', this.handleClickOutside);
  }
}
</script>
<style scoped>
/*
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body {
  padding: 20px;
  min-height: 100vh;
  background-color: rgb(234, 242, 255);
}

input {
  display: block;
  width: 350px;
  margin: 20px auto;
  padding: 10px 45px;

  background-size: 15px 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}


.fruit {
  background-color: rgb(97, 62, 252);
  cursor: pointer;
}*/

.item {
  width: 350px;
  margin: 0 auto 10px auto;
  padding: 10px 20px;
  color: white;
  border-radius: 5px;

}
.error {
  padding-top: 40px;
  color: orangered;
  width: 100%;
  text-align: center;
}

.autocomplete {
  position: relative;
  z-index: 1000;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: auto;
  overflow: auto;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: #ebcec6;
    color: white;
  }
.li1 {
  background-color: #bcc5c3;
  padding-top: 15px;
  padding-bottom: 15px;
}
.li0 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.presult {
  font-weight: bold;
  font-size: 18px;
  color: maroon;
}
</style>