<template>
  <div class="wrapper">
    <Splide
      aria-labelledby="autoplay-example-heading"
      :options="options"
      :has-track="false"
    >
      <div class="splidertrackcss">
        <SplideTrack>
          <SplideSlide v-for="image in images" :key="image.caption">
            <img :src="image.imageUrl" :alt="image.caption" class="spliderimage">
          </SplideSlide>
        </SplideTrack>
      </div>

      <div class="splide__progress">
        <div class="splide__progress__bar">
        </div>
      </div>

      <button class="splide__toggle formatround">
        <span class="splide__toggle__play"><i class="fa-solid fa-play"></i></span>
        <span class="splide__toggle__pause"><i class="fa-solid fa-pause"></i></span>
      </button>
      <div class="splide__arrows">
        <button class="splide__arrow splide__arrow--prev">
          <i class="fa-solid fa-circle-chevron-left facircle"></i>
        </button>
        <button class="splide__arrow splide__arrow--next">
          <i class="fa-solid fa-circle-chevron-right facircle"></i>
        </button>
      </div>
    </Splide>
 
  </div>
</template>

<script>
import { Splide, SplideSlide, SplideTrack, Options } from '@splidejs/vue-splide';
import { generateSlides } from '../utils';

export default {
  name: 'AutoplayExample',
   components: {
    Splide,
    SplideTrack,
    SplideSlide,
  },
  data() {
    return {
      images: [
        {
          imageUrl: 'https://hq.nexgbits.com/media/imgupload/slide-1.jpg',
          caption: 'Photo by 1'
        },
        {
          imageUrl: 'https://hq.nexgbits.com/media/imgupload/slide-2.jpg',
          caption: 'Photo by 2'
        },
        {
          imageUrl: 'https://hq.nexgbits.com/media/imgupload/slide-1.jpg',
          caption: 'Photo by 3',
        },
        {
          imageUrl: 'https://hq.nexgbits.com/media/imgupload/slide-2.jpg',
          caption: 'Photo by 4'
        }
      ]
    }
  },

  setup() {
    const slides = generateSlides();
    const options = {
      // rewind  : true,
      arrows: true,
      gap     : '1rem',
      autoplay: true,
      // perPage: 3,
      height   : '25rem',
      // focus    : 'center',
      // autoWidth: true,
      pagination: false,
      type     : 'loop',
      trimSpace: 'move',
      focus    : 'center',
      autoWidth: true,
      updateOnMove: true,
      perPage     : 3,
      live: true,
      isNavigation: true,
      slideFocus: true,

    };

    return {
      slides,
      options,
    }
  },
} 
</script>
<style scoped>
.splide__arrow{
  margin-top: 210px;
}
.splide__arrow--prev{
  margin-left: 100px;

}
/*.splide__arrow--next{
  margin-right: 870px;
}*/
.facircle{
 font-size: 30px;
 color: white;
}
.splidertrackcss {
  position: relative;
}
.formatround {
  border-radius: 50%;
  width: 30px;
  background-color: white;
  margin-top: 20px;
  opacity: 0.7;
}
.formatround:hover {
 opacity: 1;
}
.wrapper {
  padding-left: 200px; 
  padding-right: 200px; 
  background-color: black;
}
/* For browsers that support :focus-visible */
.splide__arrow:focus-visible {
  outline: 3px solid skyblue;
  margin-top: 10px;
}
.spliderimage {
  width: 600px; 
  height: 400px;
  opacity: 0.9;
}
.spliderimage:hover {
  opacity: 1;
  background-color: skyblue;
}
/* For other browsers */
.splide.is-focus-in .splide_arrow:focus {
  outline: 3px solid rgb(3, 30, 41);
}
/*===================MEDIA SCREENS TAMPLATES===============*/
     /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .wrapper {
      padding-left: 5px; 
      padding-right: 5px; 
    }
    /*.splide__arrow--next{
      margin-right: 220px;
    }*/
  }


  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .wrapper {
      padding-left: 5px; 
      padding-right: 5px; 
    }
   /* .splide__arrow--next{
      margin-right: 380px;
    }*/

  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .wrapper {
      padding-left: 25px; 
      padding-right: 25px; 
    }
   /* .splide__arrow--next{
      margin-right: 520px;
    }*/
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .wrapper {
      padding-left: 50px; 
      padding-right: 50px; 
    }
    /*.splide__arrow--next{
      margin-right: 680px;
    }*/
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .wrapper {
      padding-left: 100px; 
      padding-right: 100px; 
    }
    /*.splide__arrow--next{
      margin-right: 800px;
    }*/
  } 
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1400px) {
    .wrapper {
      padding-left: 200px; 
      padding-right: 200px; 
    }
   /* .splide__arrow--next{
      margin-right: 880px;
    }*/
    } 

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1600px) {

    } 
</style>