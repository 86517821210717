<template>
    <div >
  
      <!-- <transition  v-if="isLoading">
        <pulse-loader></pulse-loader>
      </transition>
  
      <transition name="fade" v-if="!isLoading"> -->
 
  
          <!-- <form @submit.prevent="greet"> -->

  
            <div class="form-group" v-if="voiceList.length">
              <select class="form-control" id="voices" v-model="selectedVoice">
                <option v-for="(voice, index) in voiceList" :data-lang="voice.lang" :value="index">
                  {{ voice.name }} ({{ voice.lang }})
                </option>
              </select>
            </div>
  
            <!-- <div class="form-group">
              <label for="your-name">Your name</label>
              <input class="form-control" id="your-name" type="text" v-model="name" required>
            </div>
  
            <button type="submit" class="btn btn-success">Greet</button> -->
          <!-- </form> -->
  

      <!-- </transition> -->
  
    </div>
  </template>
  
  <script>
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  
  export default {
    name: 'speech',
    props: ['voicetext'],
    data () {
      return {
        isLoading: true,
        name: this.voicetext,
        selectedVoice: 0,
        synth: window.speechSynthesis,
        voiceList: [],
        greetingSpeech: new window.SpeechSynthesisUtterance()
      }
    },
  
    components: {
      PulseLoader
    },
  
    mounted () {
      // wait for voices to load
      // I can't get FF to work without calling this first
      // Chrome works on the onvoiceschanged function
      this.voiceList = this.synth.getVoices()
  
      if (this.voiceList.length) {
        this.isLoading = false
      }
  
      this.synth.onvoiceschanged = () => {
        this.voiceList = this.synth.getVoices()
        // give a bit of delay to show loading screen
        // just for the sake of it, I suppose. Not the best reason
        setTimeout(() => {
          this.isLoading = false
        }, 800)
      }
  
      this.listenForSpeechEvents()
      this.greetingSpeech.text = `${this.name}`
  
      this.greetingSpeech.voice = this.voiceList[this.selectedVoice]

      this.synth.speak(this.greetingSpeech)
   
    },
  
    methods: {
      /**
       * React to speech events
       */
      listenForSpeechEvents () {
        this.greetingSpeech.onstart = () => {
          this.isLoading = true
        }
  
        this.greetingSpeech.onend = () => {
          this.isLoading = false
        }
      },
  
      /**
       * Shout at the user
       */
      greet () {
        // it should be 'craic', but it doesn't sound right
        // this.greetingSpeech.text = `${this.name}`
  
        // this.greetingSpeech.voice = this.voiceList[this.selectedVoice]
  
        // this.synth.speak(this.greetingSpeech)
      }
    }
  }
  </script>
  
  <style scoped>


  
    .fade-enter-active, .fade-leave-active {
      transition: opacity ease .5s;
    }
  
    .fade-enter, .fade-leave-to {
      opacity: 0;
    }
  
    .btn-success {
      background: #43C6AC;
      border-color: #43C6AC;
      cursor: pointer;
    }
  
.form-group {
  visibility: hidden;
}
  </style>