<template>

          <div class="player">
            <video id="myVideo" controls class="videocss">
                <source src="http://vjs.zencdn.net/v/oceans.mp4" type="video/mp4">
              </video>
          </div>

  </template>
  
  <script>
    // custom skin css
    // import '../custom-theme.css'
    
    export default {
      data() {
        return {
          // videojs options
          vid: '',
        }
      },
 
      mounted() {
        this.vid = document.getElementById("myVideo");
        let vid1 = document.getElementById("myVideo");
            vid1.loop = true;
        // this.vid.onplay = function() {
        // alert("The video has started to play");
        // };
      },
   
      methods: {
       
      }
    }
  </script>

<style scoped>
.videocss {
  width: 100%;
  height: 100%;
}
</style>
  
  
