<template>
    <div style="padding: 100px;" >
        <div class="innerdiv imageformat zoom" v-for="i in gitval">
            <div>
                <h4>Testing inner data</h4>
            </div>
        </div>
        <!-- <img src=""  class="imageformat zoom" /> -->
    </div>
</template>
<script>

export default {
    data(){
        return {
            gitval: 3,
        }
    }
}
</script>

<style scoped>
.zoom {
    transition: transform .90s;
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }
  .zoom:hover {
    -ms-transform: scale(.5); /* IE 9 */
    -webkit-transform: scale(.5); /* Safari 3-8 */
    transform: scale(1.2); 
  }
  .imageformat{
    width: 100%; 
    border-radius: 5px;
    width: 300px; 
    height: 400px; 
  }
.innerdiv {
 background-image: url('https://hq.nexgbits.com/media/imgupload/slide-2.jpg');
 width: 400px;
 height: 500px;
}
</style>