<template>
    <div>
      <NavBar />
    
      <section  class="navbarsection">
        <div class="txtdata">
          <h1  v-html="imagetext" :class="formatvhtml"></h1>
        </div>
          <div class="videosection" >
            <div  v-show="pvisvideo === true && pvpictureimage === false ">
              <Transition class="transition thing">
                <video-background 
                  playbackRate=0.2
                  :src="src"
                  :poster="poster"
                  :class="`thing playercss`"
                  :style="`height: 600px;`">
                </video-background>
              </Transition>
            </div>
          
              <div id="panorama"  v-show="pvisvideo === false && pvpictureimage === true"  :style="`height: 600px; padding-top: -5px;`">
              </div>
  
              <img :src="poster" style="width: 100%; height: 600px;" class="imgcss" 
              v-show="pvisvideo === false && pvpictureimage === false || pvisvideo === true && pvpictureimage === true" />
     
        </div>
        <div class="overlay"></div>
        
      </section>
  
      <!-- First section starts here-->
      <section>
        <div class="firstsec">
        <div class="row wrapcontent">
          <div class="col-xs-12 col-sm-12 col-md-6">
            <div v-html="twosecdataleft" class=""></div>
          </div>
          <ImageHover :sectionimage="seconeimage" :mdcolwidth="mdcolwidth" :btmimagetext="btmimagetext" />
        </div>
      </div>
      </section>
      <!-- <TwoEqualsections :lefttext="twosecdataleft" :righttext="twosecdataright" /> -->
  
      <section class="twoseccss1">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 leftside">
             
              <HtmlVideoplayer />
              <TextTospeech :voicetext="msgreading"/>
          </div>
          <div class="ol-xs-12 col-sm-12 col-md-6 ">
            <h3>Test data text</h3>
            <div v-html="sectworight" class="rightside"></div>
          </div>
        </div>
      </section>
      <!-- <TwoEqualsections :lefttext="twosecdataleft" righttext="" twosectheme1="twoseccss1" /> -->
  
      <ThreecardsSection :allcardsinfo="allcardsinfo" :allcardmdcol="allcardmdcol"/>
     
      <section style="background-color: #003; padding-bottom: 100px;">
        <SwiperSlide :SliderData="allslidesinfo" :slidertextinfo="allslidestext" swipertheme="swiperthemecss"/>
      </section>
  
  
      <div class="footerformat">
        <FooterPage class="sectionfooter"/>
      </div>
    </div>
    </template>
    
    <script>
    import NavBar from '@/components/NavBar.vue';
    import FooterPage from '@/components/FooterPage.vue';
    import TwoEqualsections from '@/components/TwoEqualsections.vue';
    import TextTospeech from '@/components/TextTospeech.vue';
    import HtmlVideoplayer from '@/components/HtmlVideoplayer.vue';
    import ImageHover from '@/components/ImageHover';
    import ThreecardsSection from '@/components/ThreecardsSection.vue';
    import SwiperSlide from '@/components/SwiperSlide.vue';
    // import {Swiper, SwiperSlide} from 'swiper/vue';
    import axios from 'axios';
    import '../globalDeclare'
    
    
    import 'swiper/css';
    import 'swiper/css/pagination';
    import 'swiper/css/effect-fade';
    import 'swiper/css/autoplay';
    import 'swiper/css/navigation';
    
    export default {
      name: 'HomeView',
      components: {
      NavBar, FooterPage, TwoEqualsections, TextTospeech, HtmlVideoplayer, ImageHover, ThreecardsSection, SwiperSlide
    },
    
    data() {
      return {
        carouselSlides: [],
        videoimage: [],
        imagetext: '',
        src: '',
        playrate: '',
        poster: '',
        // pvwebm: '',
        // pvmp4: '',
        pvstyle: '',
        pvsrc: '',
        pvisvideo: false,
        pvpanorama1: '',
        pvpanorama2: '',
        pvpanorama3: '',
        pvhotspot1: '',
        pvhotspot2: '',
        pvhotspot3: '',
        pvpictureimage: false,
  
        allpages: [],
        allslidesinfo: [],
        allslidestext: '',
  
        //Twosidetext variables
        twosecdataleft: '',
        twosecdataright: '',
        sectwoleft: '',
        sectworight: '',
        msgreading: "What do you mean by this?",
        seconeimage: '',
        myImgUrl: myUrlPath,
        imagetext: '',
        mdcolwidth: 6,
        btmimagetext: '',
        allcardsinfo: '',
        allcardmdcol: 4,
  
   
      }
    },
    
    setup() {
    
    },
    async mounted(){
    
      await axios.get('allpagespv/homepage').then(
        response => {
          this.videoimage = response.data
          
          this.imagetext = this.videoimage.pvdisplaytext 
          this.src = this.videoimage.pvsrc
          this.playrate = this.videoimage.playbackrate
          this.poster = this.videoimage.pvposter
          // this.pvsrc = this.videoimage.pvsrc
          // this.pvmp4 = this.videoimage.pvmp4
          this.pvstyle = this.videoimage.pvstyle
          this.pvisvideo = this.videoimage.pvisvideo
          this.pvpanorama1 = this.videoimage.pvpanorama1
          this.pvpanorama2 = this.videoimage.pvpanorama2
          this.pvpanorama3 = this.videoimage.pvpanorama3
          this.pvhotspot1 = this.videoimage.pvhotspot1
          this.pvhotspot2 = this.videoimage.pvhotspot2
          this.pvhotspot3 = this.videoimage.pvhotspot3
          this.pvpictureimage = this.videoimage.pvpictureimage
    
          if (this.pvisvideo === true) {
            this.src = this.videoimage.pvsrc
          }
          else {
              const config = {
              autoLoad: true,
              scenchange: true,
              compass: false,
              northOffset: -20.5,
              default: {
                  firstScene: "hotspotoffice",
                  sceneFadeDuration: 1000,
                  autoRotate: -.7,
                  setTimeout: 100,
                  preview: this.poster
                  
              },
              scenes: {
                  hotspotoffice: {
                      hfov: 180,
                      pitch: 0,
                      yaw: 0,
                      type: "equirectangular",
                      panorama: this.pvpanorama1,
                      // hotSpots: [
                      //     {
                      //         pitch: 4,
                      //         yaw: 21.8,
                      //         type: "scene",
                      //         sceneId: "anotherhotspot",
                      //         text: this.pvhotspot1,
                      //     }
                      // ]
                  },
                  anotherhotspot: {
                        hfov: 180,
                        pitch: 0,
                        yaw: 0,
                        type: "equirectangular",
                        panorama: this.pvpanorama2,
                        // hotSpots: [
                        //     {
                        //         pitch: 8,
                        //         yaw: -58,
                        //         type: "scene",
                        //         sceneId: "lasthotspot",
                        //         text: this.pvhotspot2,
                        //     }
                        // ]
                    },
                    lasthotspot: {
                        hfov: 180,
                        pitch: 0,
                        yaw: 0,
                        type: "equirectangular",
                        panorama: this.pvpanorama3,
                        // hotSpots: [
                        //     {
                        //         pitch: 8,
                        //         yaw: -58,
                        //         type: "scene",
                        //         sceneId: "hotspotoffice",
                        //         text: this.pvhotspot3,
                        //     }
                        // ]
                    },
                  
                }
            };
            pannellum.viewer('panorama', config);
          }
        })
        .catch((error) => {
            console.log(error)
          })
  
      //get home page first section info
      await axios.get('gettwoequalsectioninfo/homepage/secone').then(
        response => {
          this.allpages = response.data
          this.twosecdataleft = response.data[0].twosecleft
          this.twosecdataright = response.data[0].twosecright
        })
        .catch((error) => {
            console.log(error)
          })
  
      //get homepage second section info
      await axios.get('gettwoequalsectioninfo/homepage/sectwo').then(
        response => {
          // this.allpages = response.data
          this.sectwoleft = response.data[0].twosecleft
          this.sectworight = response.data[0].twosecright
        })
        .catch((error) => {
            console.log(error)
          })
  
      //get homepage first section image
      await axios.get('getallimages/homepage/projects1').then(
        response => {
          // this.allpages = response.data
          this.seconeimage = response.data[0].imgurl
          this.btmimagetext = response.data[0].imagetext
        })
        .catch((error) => {
            console.log(error)
          })
  
      //get allcards info
      await axios.get('getallcardsinfo/homepage').then(
        response => {
          this.allcardsinfo = response.data
        })
        .catch((error) => {
            console.log(error)
          })
  
      //get allcards info
      await axios.get('getallslidersinfo/homepage/ourofferings').then(
        response => {
          this.allslidesinfo = response.data
          console.log(this.allcardsinfo)
        })
        .catch((error) => {
            console.log(error)
          })
  
      //get allcards info                  
      await axios.get('getallslidersgeneric/homepage/ourofferings').then(
        response => {
          this.allslidestext = response.data[0].slidetext
        })
        .catch((error) => {
            console.log(error)
          })
       
          //------------all sliders(generic)---------------
    await axios.get('getallslidersgeneric/policiesandcommitment').then(
      response => {
          this.SliderData = response.data
    })
    .catch((error) => {
        console.log(error)
      })
      },
    
      methods: {
  
      },
    
    }
    </script>
    
    <style scoped lang="scss">
    @import "@/assets/layout.css";
      .transition {
      transition: all 3s ease-out;
      animation: fadeInAnimation ease 3s;
      animation-iteration-count: 1;
      animation-fill-mode: backwards;
    }
    
    @keyframes fadeInAnimation {
      0% {
          opacity: 0;
      }
      100% {
          opacity: 1;
      }
    }
    .videosection {
      margin-top: -60px;
      background-color: #000000;
    }
   
    .overlay{
      opacity: .4;
      height: 600px;
      background-color: #000000;
  
     }
     .overlay {
      opacity: 0.55;
      width: 100%;
      height: 600px;
      background: linear-gradient(to bottom, #000000, transparent);
      margin-top: -600px;
  
    }
  
     .playercss {
      max-height: 300%; 
      height: 70vh;
  
     }
  
     .txtdata {
      position:absolute;
      display: block;
      z-index: 10;
      color: white;
    }
    
    [data-aos="new-animation"] {
      opacity: 0;
      transition-property: transform, opacity;
    }
    
    .twoseccss {
      background-color: rgb(206, 203, 203);
      padding-bottom: 50px;
    }
    .twoseccss1 {
      background-color: #003;
      color: white;
      padding: 100px;
    }
    .leftside {
      overflow-x: scroll;
    }
    .firstsec {
      padding: 100px;
    }
    .swiperthemecss {
      background-color: #003;
    }
  
    @media (min-width: 325px) and (max-width: 550px) {
      .txtdata {
        width: 500px;
        padding-left: 10px;
        margin-top: 200px;
      }
      .leftside {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
      }
      .twoseccss1 {
        padding: 20px;
      }
      .rightside {
        width: 100%;
        padding-left: 10px;
      }
      .firstsec {
        padding:20px;
      }
    }
  
     /*===================MEDIA SCREENS TAMPLATES===============*/
     /* Extra small devices (phones, 600px and down) */
     @media only screen and (max-width: 600px) {
      .txtdata {
        width: 400px;
        padding-left: 10px;
        margin-top: 200px;
  
      }
     
     }
    
    
     /* Small devices (portrait tablets and large phones, 600px and up) */
     @media only screen and (min-width: 600px) {
      .txtdata {
        width: 600px;
        padding-right: 10px;
      }
      .txtdata {
        width: 400px;
        padding-left: 40px;
        margin-top: 200px;
        text-align: center;
      }
     }
     
     /* Medium devices (landscape tablets, 768px and up) */
     @media only screen and (min-width: 768px) {
       .txtdata {
        width: 700px;
        padding-right: 10px;
      }
     }
     
     /* Large devices (laptops/desktops, 992px and up) */
     @media only screen and (min-width: 992px) {
      .txtdata {
        width: 800px;
        padding-right: 10px;
  
      }
     }
     
     /* Extra large devices (large laptops and desktops, 1200px and up) */
     @media only screen and (min-width: 1200px) {
      .txtdata {
  
        width: 900px;
        padding-right: 10px;
      }
     } 
    
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1400px) {
      .txtdata {
        width: 800px;
        margin-top: 200px;
        padding-right: 10px;
        padding-left: 130px;
      }
      } 
  
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1600px) {
      .txtdata {
        width: 800px;
        padding-left: 240px;
  
      }
      } 
    </style>